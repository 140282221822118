import * as EmailValidator from 'email-validator';

const datasoapValidateEmail = async (value) => {
    
    let emailResult = false
  
    if (EmailValidator.validate(value)) {
        //Test email 
        await fetch(
            `https://api.datasoap.co.uk/?output=JSON&validationemail=${value}&type=Email`,
            {
                method: 'POST',
                headers: {
                    Authorization: process.env.GATSBY_DATASOAP_AUTH
                }
            }
        )
            .then((response) => {
                if (response.ok) {
     
                    return response.json()
                    
                } else {
                    
                    emailResult = false 
      
                }
            })
            .then((data) => {
                emailResult = data.DataSoapAPIResponse.EmailValidationResult.Reason === 'Accepted Email' || data.DataSoapAPIResponse.EmailValidationResult.Reason === 'Low Deliverability' ? true : false
                
            })
            .catch((error) => {
 
                emailResult = true 
            })
    } else {

        emailResult = false; 

    }


console.log( 'emailResult from async: '+ emailResult);
return emailResult


}

export default datasoapValidateEmail
